import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { getModels, useMounted } from '../common/restAPI'
import { modelTypeToString } from '../common/utils'
import { CreatorFilter } from './creator-filter'
import TablePaginationActions from './TablePaginationActions'

const cookies = new Cookies()
const rowsPerPageCookieName = 'rowsPerPage-ListModels'

export default function ListModels(props) {
  const defaultRowsPerPage = 10
  const rowsPerPageFromCookie = Number.parseInt(cookies.get(rowsPerPageCookieName))
  const currentRowsPerPage = Number.isNaN(rowsPerPageFromCookie) ? defaultRowsPerPage : rowsPerPageFromCookie

  const { userInfo, filteredOwner } = props

  const [modelList, setModelList] = useState([])
  const [totalQueryCount, setTotalQueryCount] = useState(0)
  const [query, setQuery] = useState({
    page: props.page ?? 0,
    rowsPerPage: props.rowsPerPage ?? currentRowsPerPage,
    filteredOwner: props.filteredOwner,
  })

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (newPage != query.page) {
        updateQueryAndPageURL({ ...query, page: newPage })
      }
    },
    [query],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const newRowsPerPage = event.target.value ?? currentRowsPerPage
      if (query.rowsPerPage !== newRowsPerPage) {
        let newPage = query.page
        let queryUpdate = { rowsPerPage: newRowsPerPage }
        cookies.set(rowsPerPageCookieName, newRowsPerPage)
        if (totalQueryCount < query.page * newRowsPerPage) {
          newPage = Math.floor(totalQueryCount / newRowsPerPage)
          queryUpdate.page = newPage
        }
        updateQueryAndPageURL({ ...query, ...queryUpdate })
      }
    },
    [currentRowsPerPage, query],
  )

  const handleChangeCreator = useCallback(
    (event) => {
      const owner = event.target.value
      if (query.owner != owner) {
        // Reset the page too
        updateQueryAndPageURL({ ...query, page: 0, filteredOwner: owner })
      }
    },
    [query],
  )

  const updateQueryAndPageURL = useCallback((newQuery) => {
    setQuery(newQuery)
    updatePageURL(newQuery)
  }, [])

  const updatePageURL = (newQuery) => {
    let url = '/models/'
    let extensions = new URLSearchParams()
    if (newQuery.page >= 1) {
      // Only one-based in the URL
      extensions.set('page', (newQuery.page + 1).toString())
    }
    if (newQuery.rowsPerPage != defaultRowsPerPage) {
      extensions.set('rowsPerPage', newQuery.rowsPerPage.toString())
    }
    if (newQuery.filteredOwner) {
      extensions.set('ownerKind', newQuery.filteredOwner.kind)
      extensions.set('ownerName', newQuery.filteredOwner.owner)
    }

    let ext_str = extensions.toString()
    if (ext_str.length > 0) {
      url = `${url}?${ext_str}`
    }

    window.history.replaceState(null, '', url)
  }

  useEffect(() => {
    document.title = 'Models | AI Hub'
  }, [])

  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()
    let defaultParams = {}
    if (query?.filteredOwner && query.filteredOwner.kind === 'user') {
      defaultParams.creator = query.filteredOwner.owner
    }

    getModels(
      query.page,
      query.rowsPerPage,
      mountState,
      (modelListPb) => {
        setModelList(modelListPb.getModelsList())
        setTotalQueryCount(modelListPb.getTotalQueryCount())
      },
      (err) => {
        console.log(err)
      },
      defaultParams,
    )

    return tearDownMounted
  }, [query])

  const renderJobs = () => {
    let elements = []
    if (modelList && Array.isArray(modelList)) {
      elements = modelList.map((item) => (
        <TableRow key={item.getModelId()}>
          <TableCell style={{ maxWidth: '25vw' }}>
            <Link
              to={`/models/${item.getModelId()}/`}
              style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {item.getName()}
            </Link>
          </TableCell>
          <TableCell>
            <span className="idbox">{item.getModelId()}</span>
          </TableCell>
          <TableCell>{modelTypeToString(item.getModelType())}</TableCell>
          <TableCell>{item.getOwner().getEmail()}</TableCell>
          <TableCell>{item.getCreationTime().toDate().toLocaleString()}</TableCell>
        </TableRow>
      ))
    }
    return (
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>
                <CreatorFilter userInfo={userInfo} onChange={handleChangeCreator} filteredOwner={filteredOwner} />
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={7}
                count={totalQueryCount}
                rowsPerPage={query.rowsPerPage}
                page={query.page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: false,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
            <TableRow key="header">
              <TableCell xs={600}>Model Name</TableCell>
              <TableCell>Model ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Creation Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{elements}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div className="main" data-testid="listmodels-main">
      <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
        Models
      </Typography>
      {renderJobs()}
    </div>
  )
}

ListModels.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  userInfo: PropTypes.object,
  filteredOwner: PropTypes.object,
}
