/* istanbul ignore file */

import ErrorIcon from '@mui/icons-material/Error'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

export function ErrorButton({ title }: { readonly title: string }) {
  return (
    <Tooltip title={title}>
      <IconButton sx={{ color: 'var(--orange)' }} aria-label="info" component="span">
        <ErrorIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  )
}
