import type { ReactNode } from 'react'
import React from 'react'

export type ErrorBoundaryProps = {
  readonly children: ReactNode
}

export type ErrorBoundaryState = {
  readonly hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // eslint-disable-next-line functional/prefer-immutable-types
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // Render a simple error
      return (
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
          <p>Component could not be rendered.</p>
        </div>
      )
    }

    return this.props.children
  }
}
