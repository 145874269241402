import { useScript } from 'hoofd'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

/* This is a tweak of analytics.tsx from the public website */

/*
type Orientation = 'landscape' | 'portrait'
type AnalyticsEvent = {
  event: 'page-load'
  page: {
    device_orientation: Orientation
    page_title: string
    page_type: string
    document_url: string
    page_name?: string
    sc_site_section_1?: string
    sc_site_section_2?: string
    sc_site_section_3?: string
    sc_site_section_4?: string
  }
}


declare global {
  // eslint-disable-next-line no-var
  var adobeDataLayer: AnalyticsEvent[] | undefined
}
*/

function landscapeOrPortraitOrientation() {
  // `orientation` has a value like "landscape-primary"
  // https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/type
  if (window.screen.orientation) {
    // eslint-disable-next-line compat/compat
    return window.screen.orientation.type.split('-')[0]
  } else {
    // screen.orientation is not available in 2+ year-old safari versions
    return ''
  }
}

function stashAnalyticsEvent(pathname) {
  // Extract '/'-delimited pathname components
  const pathComponents = [...pathname.matchAll(/[^/]+/g)].map((match) => match[0])
  if (pathComponents.length === 0) {
    pathComponents.push('home')
  }

  const eventInfo = {
    event: 'page-load',
    page: {
      device_orientation: landscapeOrPortraitOrientation(),
      page_title: document.title,
      page_type: pathComponents[0],
      document_url: location.href,
    },
  }

  let sectionName = 'qc:aihub'
  let section = 1
  while (pathComponents.length > 0) {
    sectionName += ':' + pathComponents.shift()
    eventInfo.page[`sc_site_section_${section++}`] = sectionName
  }
  eventInfo.page.page_name = sectionName

  window.adobeDataLayer = window.adobeDataLayer ?? []
  window.adobeDataLayer.push(eventInfo)
}

export default function Analytics() {
  const location = useLocation()

  useEffect(() => {
    // store analytics on every "page load" (i.e. change in URL path)
    stashAnalyticsEvent(location.pathname)
  }, [location.pathname])

  useScript({
    // eslint-disable-next-line no-undef
    src: process.env.REACT_APP_ADOBE_ANALYTICS_URL,
    async: true,
  })

  return <></>
}
