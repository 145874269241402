import type { Theme } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

export const theme: Theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#E9EBEE', // Qualcomm Nickel
        },
      },
    },
  },
  palette: {
    // MUI Themes don't support CSS variables yet.
    primary: {
      main: '#3253DC', // Qualcomm Blue
    },
    secondary: {
      main: '#4A5A75', // Qualcomm Gunmetal
    },
  },
  typography: {
    fontFamily: '"Qualcomm Next", Roboto, Helvetica, Arial, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
})
