/* istanbul ignore file */

import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getJobSummaries, useMounted } from '../common/restAPI'
import type { LegacyUserInfo } from '../common/types/legacy-types'
import type { JobListRawJsProtobuf } from '../common/types/raw-javascript-protobuf-types'

export function Home({ userInfo }: { readonly userInfo: LegacyUserInfo }) {
  const [jobCount, setJobCount] = useState<number>()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mountState, tearDownMounted] = useMounted()

    if (userInfo.user) {
      getJobSummaries(
        0,
        0,
        mountState,
        (jobListPb: JobListRawJsProtobuf) => {
          setJobCount(jobListPb.getTotalQueryCount())
        },
        () => {
          if (mountState.checkMounted()) {
            // Unexpected error; just redirect somewhere to avoid a blank page
            setJobCount(-1)
          }
        },
        { creator: userInfo.user.getEmail() },
      )
    }

    return tearDownMounted
  }, [userInfo])

  // Has this user ever created a job? If not, redirect to account setup info
  if (jobCount === undefined) {
    return <></> // job query is not finished; keep waiting
  } else if (jobCount === 0) {
    return <Navigate to="/account/" replace />
  } else {
    return <Navigate to="/jobs/" replace />
  }
}
