/* istanbul ignore file */
/* eslint-disable functional/no-let */

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { jobStateToString } from '../common/utils'
import { JobState } from '../generated/api/public_api'
import { RadialSeparators } from './radial-separators'

function RunningJobStateProgressIcon({
  showText,
  state,
  textSize,
  type,
}: {
  readonly showText?: boolean
  readonly state: JobState
  readonly textSize?: number
  readonly type: string
}) {
  let progress = 0
  let current_step = 0
  let total_steps = 3
  if (type === 'compile' || type === 'quantize' || type === 'link') {
    total_steps = 2
  }

  let color = 'var(--qualcomm_blue)'
  switch (state) {
    case JobState.JOB_STATE_FAILED: {
      color = 'var(--alert-red)'
      progress = 100
      current_step = total_steps
      break
    }
    case JobState.JOB_STATE_DONE: {
      color = 'var(--green)'
      progress = 100
      current_step = total_steps
      break
    }
    case JobState.JOB_STATE_CREATED: {
      progress = 5
      current_step = 0
      break
    }
    case JobState.JOB_STATE_OPTIMIZING_MODEL: {
      progress = 49.2
      current_step = 1
      break
    }
    case JobState.JOB_STATE_QUANTIZING_MODEL: {
      progress = 49.2
      current_step = 1
      break
    }
    case JobState.JOB_STATE_PROVISIONING_DEVICE: {
      progress = 38.3
      current_step = 1
      break
    }
    case JobState.JOB_STATE_RUNNING_INFERENCE: {
      progress = 71.6
      current_step = 2
      break
    }
    case JobState.JOB_STATE_MEASURING_PERFORMANCE: {
      progress = 71.6
      current_step = 2
      break
    }
    default: {
      progress = 0
    }
  }
  return (
    <CircularProgressbarWithChildren
      value={progress}
      text={showText ? `${current_step.toString()}/${total_steps.toString()}` : ''}
      strokeWidth={10}
      styles={{
        path: {
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          stroke: color,
        },
        text: {
          fill: color,
          fontWeight: 'bold',
          fontSize: textSize,
        },
      }}
    >
      <RadialSeparators
        count={total_steps}
        style={{
          width: '2px',
          backgroundColor: 'white',
          height: '15%',
        }}
      />
    </CircularProgressbarWithChildren>
  )
}

export function CompileJobStateIconTooltip(): string {
  return (
    'Status sequence:\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_CREATED) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_OPTIMIZING_MODEL) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_DONE) +
    ' / ' +
    jobStateToString(JobState.JOB_STATE_FAILED)
  )
}

export function QuantizeJobStateIconTooltip(): string {
  return (
    'Status sequence:\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_CREATED) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_QUANTIZING_MODEL) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_DONE) +
    ' / ' +
    jobStateToString(JobState.JOB_STATE_FAILED)
  )
}

export function LinkJobStateIconTooltip(): string {
  return (
    'Status sequence:\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_CREATED) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_LINKING_MODELS) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_DONE) +
    ' / ' +
    jobStateToString(JobState.JOB_STATE_FAILED)
  )
}

export function ProfileJobStateIconTooltip(includesCompile?: boolean): string {
  return includesCompile
    ? 'Status sequence:\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_CREATED) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_OPTIMIZING_MODEL) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_PROVISIONING_DEVICE) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_MEASURING_PERFORMANCE) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_DONE) +
        ' / ' +
        jobStateToString(JobState.JOB_STATE_FAILED)
    : 'Status sequence:\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_CREATED) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_PROVISIONING_DEVICE) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_MEASURING_PERFORMANCE) +
        '\n' +
        '- ' +
        jobStateToString(JobState.JOB_STATE_DONE) +
        ' / ' +
        jobStateToString(JobState.JOB_STATE_FAILED)
}

export function InferenceJobStateIconTooltip(): string {
  return (
    'Status sequence:\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_CREATED) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_PROVISIONING_DEVICE) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_RUNNING_INFERENCE) +
    '\n' +
    '- ' +
    jobStateToString(JobState.JOB_STATE_DONE) +
    ' / ' +
    jobStateToString(JobState.JOB_STATE_FAILED)
  )
}

export function getJobStateIconTooltip(jobType: string) {
  switch (jobType) {
    case 'compile': {
      return CompileJobStateIconTooltip()
    }
    case 'profile': {
      return ProfileJobStateIconTooltip()
    }
    case 'inference': {
      return InferenceJobStateIconTooltip()
    }
    case 'quantize': {
      return QuantizeJobStateIconTooltip()
    }
    case 'link': {
      return LinkJobStateIconTooltip()
    }
    default: {
      return ''
    }
  }
}

export function JobStateIcon({
  state,
  type,
  marginRight,
}: {
  readonly state: JobState
  readonly type: string
  readonly marginRight?: string
}) {
  const element = (() => {
    switch (state) {
      case JobState.JOB_STATE_FAILED: {
        return <ErrorRoundedIcon sx={{ fontSize: '26px', color: 'var(--alert_red)' }} />
      }
      case JobState.JOB_STATE_DONE: {
        return <CheckCircleRoundedIcon sx={{ fontSize: '26px', color: 'var(--green)' }} />
      }
      default: {
        return <RunningJobStateProgressIcon state={state} showText={true} textSize={40} type={type} />
      }
    }
  })()

  return (
    <div className="state-icon" style={{ marginRight }}>
      <div className="state-icon-inner">{element}</div>
    </div>
  )
}
