/* istanbul ignore file */

import { median } from '../common/utils'

export const NOT_AVAILABLE = 'N/A'

export function Metric({ className, value }: { readonly className?: string; readonly value: string }) {
  const AvailableMetric = () => {
    return <span className={className}>{value}</span>
  }

  const UnavailableMetric = () => {
    return (
      <span className={className} style={{ color: '#aaa' }}>
        {NOT_AVAILABLE}
      </span>
    )
  }

  return value === NOT_AVAILABLE ? <UnavailableMetric /> : <AvailableMetric />
}

export function ArrayMetric({
  className,
  valueFormatter,
  values,
}: {
  readonly className?: string
  readonly values: readonly number[]
  readonly valueFormatter: (value: number) => string
}) {
  const AvailableArrayMetric = () => {
    if (values.length === 1) {
      return <span className={className}>{valueFormatter(values[0])}</span>
    }

    return (
      <>
        <p>
          <span className="right-aligned-metric">
            <span style={{ color: '#aaa' }}>(min)</span> {valueFormatter(Math.min(...values))}
          </span>
        </p>
        <p>
          <span className="right-aligned-metric">
            <span style={{ color: '#aaa' }}>(median)</span> {valueFormatter(median(values) ?? 0)}
          </span>
        </p>
      </>
    )
  }

  const UnavailableMetric = () => {
    return (
      <span className={className} style={{ color: '#aaa' }}>
        {NOT_AVAILABLE}
      </span>
    )
  }

  return values.length === 0 ? <UnavailableMetric /> : <AvailableArrayMetric />
}
