import Paper from '@mui/material/Paper'
import type { ReactNode } from 'react'

export function CodeBlock({ children }: { readonly children: ReactNode }) {
  return (
    <Paper elevation={1} sx={{ p: 1 }} style={{ backgroundColor: '#eee', overflow: 'hidden', marginBottom: '1em' }}>
      <pre>{children}</pre>
    </Paper>
  )
}
