import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import type { ReactNode } from 'react'

export function ListItemWithIcon({
  caption,
  children,
  icon,
}: {
  readonly caption?: ReactNode
  readonly children: ReactNode
  readonly icon: ReactNode
}) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'var(--qualcomm_gunmetal)', mt: 0.5 }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText disableTypography secondary={children}>
        <Typography variant="caption" color="gray">
          {caption}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}
