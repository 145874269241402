/* istanbul ignore file */

import List from '@mui/material/List'
import type { NoticeRawJsProtobuf } from '../common/types/raw-javascript-protobuf-types'
import { Notice_Severity } from '../generated/api/public_api'
import { ErrorButton } from './error-button'
import { InfoButton } from './info-button'
import { ListItemWithIcon } from './list-item-with-icon'
import { WarningButton } from './warning-button'

export function NotificationsList({ notices }: { readonly notices: readonly NoticeRawJsProtobuf[] }) {
  const errorMessages = notices.filter((m) => m.getSeverity() === Notice_Severity.ERROR).map((m) => m.getMessage())
  const warningMessages = notices.filter((m) => m.getSeverity() === Notice_Severity.WARNING).map((m) => m.getMessage())
  const infoMessages = notices.filter((m) => m.getSeverity() === Notice_Severity.INFO).map((m) => m.getMessage())
  const debugMessages = notices.filter((m) => m.getSeverity() === Notice_Severity.DEBUG).map((m) => m.getMessage())

  const errorTitle = errorMessages.length == 1 ? 'Error' : 'Errors'
  const warningTitle = warningMessages.length == 1 ? 'Warning' : 'Warnings'

  return (
    <List>
      {errorMessages.length > 0 && (
        <ListItemWithIcon caption={errorTitle} icon={<ErrorButton title={errorTitle} />}>
          {errorMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
      {warningMessages.length > 0 && (
        <ListItemWithIcon caption={warningTitle} icon={<WarningButton title={warningTitle} />}>
          {warningMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
      {infoMessages.length > 0 && (
        <ListItemWithIcon caption="Information" icon={<InfoButton title="Information" />}>
          {infoMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
      {debugMessages.length > 0 && (
        <ListItemWithIcon caption="Debugging Information" icon={<InfoButton title="Debugging Information" />}>
          {debugMessages.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </ListItemWithIcon>
      )}
    </List>
  )
}
