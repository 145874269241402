import range from 'lodash.range'
import type { CSSProperties } from 'react'

function Separator({ style, turns }: { readonly style?: CSSProperties; readonly turns: number }) {
  return (
    <div style={{ position: 'absolute', height: '100%', transform: `rotate(${turns.toString()}turn)` }}>
      <div style={style} />
    </div>
  )
}

export function RadialSeparators({ count, style }: { readonly count: number; readonly style?: CSSProperties }) {
  const turns = 1 / count
  return range(count).map((index) => <Separator key={`rad-${index.toString()}`} turns={index * turns} style={style} />)
}
