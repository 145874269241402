/* istanbul ignore file */
/* eslint-disable functional/no-let */

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import type { SelectProps } from '@mui/material/Select'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import type { StyledComponent } from '@mui/styled-engine'
import type { ReactNode } from 'react'
import React from 'react'
import type { LegacyUserInfo } from '../common/types/legacy-types'

export type Member = {
  readonly label: string
  readonly kind: 'user' | 'organization'
  readonly owner: string
}

type MenuItemsAndDefaultValue = {
  readonly menuItems: readonly React.JSX.Element[]
  readonly defaultValue?: Member
}

function createMemberList(userInfo?: LegacyUserInfo, filteredOwner?: Member): MenuItemsAndDefaultValue {
  if (userInfo?.user === undefined) {
    return { menuItems: [], defaultValue: undefined }
  }

  let memberList: Member[] = [
    { label: `${userInfo.user.getEmail()} (me)`, kind: 'user', owner: userInfo.user.getEmail() },
  ]

  if (userInfo.organization !== undefined) {
    // Add the organization
    memberList = [
      ...memberList,
      {
        label: 'My organization',
        kind: 'organization',
        owner: userInfo.user.getOrgId(),
      },
    ]

    // Add all other members of the organization
    memberList = [
      ...memberList,
      ...userInfo.organization
        .getMembers()
        .getUsersList()
        // Remove the currently logged-in user
        .filter((user) => user.getEmail() !== userInfo.user?.getEmail())
        // Represent the remaining members as users so that they can be
        // differentiated from the organization itself.
        .map<Member>((user) => ({
          label: user.getEmail(),
          kind: 'user',
          owner: user.getEmail(),
        })),
    ]
  }

  let defaultValue
  if (filteredOwner) {
    const matchingMembers = memberList.filter(
      (member) => member.kind === filteredOwner.kind && member.owner == filteredOwner.owner,
    )
    defaultValue =
      matchingMembers.length > 0
        ? matchingMembers[0]
        : ({ label: 'None', kind: 'organization', owner: '' } satisfies Member)
  }

  // TODO 13724: Fix this up - the <MenuItem> does not take in an object for
  // `value`. It only accepts strings or arrays of strings. This is probably why
  // the comment for the CreatorFilter is in place and the menu gets recreated
  // every time. I'm not sure how this actually works as-is! It shouldn't, but
  // it does <shrug />.
  const menuItems = memberList.map((member) => (
    <MenuItem key={member.owner} value={member as unknown as string}>
      {member.label}
    </MenuItem>
  ))

  return { menuItems, defaultValue }
}

/**
 * Renders a dropdown menu with a list of creators for filtering jobs and models.
 *
 * This is declared as a class component instead of a functional component because
 * re-defining the list of MenuItems causes the dropdown to be re-rendered each time
 * this component is rendered, which happens once every 5 seconds due to
 * our call to setInterval inside ListJobs. If the dropdown is open when this render
 * happens, it gets collapsed because the component thinks that the list of options
 * (aka MenuItems) has changed.
 */
export class CreatorFilter extends React.Component<CreatorFilterProps> {
  private readonly _menuItems: readonly ReactNode[]
  private readonly _defaultValue: string | { readonly label: string } | undefined
  private readonly StyledSelect: StyledComponent<SelectProps>

  constructor(props: CreatorFilterProps) {
    super(props)

    const { menuItems, defaultValue } = createMemberList(props.userInfo, props.filteredOwner)
    this._menuItems = menuItems
    this._defaultValue = defaultValue

    this.StyledSelect = styled(Select)({
      '& .MuiAutocomplete-inputRoot': {
        fontSize: '0.9em',
      },
      '& ..MuiAutocomplete-option': {
        fontSize: '0.9em',
      },
    })
  }

  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          component="span"
          sx={{
            padding: '10',
            display: 'inline-block',
            margin: '0 .8em 0 0',
          }}
        >
          Creator:
        </Box>
        <FormControl>
          <this.StyledSelect
            sx={{ width: 300 }}
            size="small"
            onChange={this.props.onChange}
            defaultValue={this._defaultValue}
          >
            {this._menuItems}
          </this.StyledSelect>
        </FormControl>
      </Box>
    )
  }
}

export type CreatorFilterProps = {
  readonly onChange?: () => void
  readonly userInfo?: LegacyUserInfo
  readonly filteredOwner?: Member
}
